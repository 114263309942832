import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {firstValueFrom, BehaviorSubject} from "rxjs";
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class BookingService {
  env: any = environment;
  public products: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public location = new BehaviorSubject(false);
  baseUrl = new URL(this.env.baseUrl);

  addToSessionData(key: string, value: any) {
    this.session.store(key, value);
  }

  getSessionData(key: string) {
    return this.session.retrieve(key);
  }

  clearSessionData(key?: string) {
    this.session.clear(key);
  }




  clearSelected(productId?: number) {
    if (productId === undefined) {
      this.clearSessionData('selected');
      this.addToSessionData('selected', JSON.stringify([]));
      return;
    }
    let selected = JSON.parse(this.getSessionData('selected'));
    this.clearSessionData('selected');
    let new_selected = Array.from(selected.values()).filter((item: any) => item.productId != productId);
    this.addToSessionData('selected', JSON.stringify(new_selected));
  }

  async getCalendarSettings(): Promise<any> {
    const apiUrl = this.baseUrl + '/wp-json/bookings/v1/calender';
    return await firstValueFrom(this.httpClient.get(apiUrl, {observe: 'body', responseType: 'json'}).pipe(take(1)));
  }

  async getLocations(): Promise<any> {
    const apiUrl = this.baseUrl + '/wp-json/bookings/v1/locations';
    return await firstValueFrom(this.httpClient.get(apiUrl, {observe: 'body', responseType: 'json'}).pipe(take(1)));
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  subtractDays(date: Date, days: number): Date {
    date.setDate(date.getDate() - days);
    return date;
  }

  ngInit(): void {
  }

  constructor(
    private httpClient: HttpClient,
    private session: SessionStorageService,
  ) {

  }
}
