export const environment = {
  production: true,
  "headline": "Suche jetzt dein Dachzelt für deine nächste Reise!",
  "buttonText": "Verfügbare Dachzelte suchen",
  "buttonText2": "Überspringen",
  "company": "Canvaz",
  "baseUrl": "https://www.canvaz.de",
  "shortcode": "cvz",
  "landingPage": "https://booking.canvaz.de",
  "defaultLocation": "72588",
};
