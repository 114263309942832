import { Component, ViewChild, OnInit } from '@angular/core';
import { BookingService } from '../booking.service';
import {
  setOptions,
  formatDate,
  MbscDatepickerOptions,
  options,
  localeDe,
  MbscDropdown,
  MbscDatepicker
} from '@mobiscroll/angular';
import { environment } from 'src/environments/environment';

const pages: number = window.innerWidth<600 ? 1 : 2;
setOptions({
  locale: localeDe,
  theme: 'ios',
  themeVariant: 'light',
  pages: pages
});

const locale = options.locale || {};
const dateFormat = locale.dateFormat || 'DD.MM.YYYY';
const isoDateFormat = 'YYYY-MM-DD';

@Component({
  selector: 'app-booking-search-form',
  templateUrl: './booking-search-form.component.html',
  styleUrls: ['./booking-search-form.component.css']
})
export class BookingSearchFormComponent implements OnInit {
  env: any = environment;
  title = 'datepicker-website';
  @ViewChild('datePicker', { static: false })
  datePicker!: MbscDatepicker;
  landingPage = new URL( this.env.landingPage );

  /* DATEPICKER */
  dateInput = '';
  selectedDate: Array<any> = ['', ''];
  firstAvailableDate = new Date();
  lastAvailableDate = new Date();
  invalid: Array<any> = [];
  calendarOptions: MbscDatepickerOptions = {
    controls: ['calendar'],
    select: 'range',
    display: 'center',
    showRangeLabels: true,
    returnFormat: 'iso8601',
    inRangeInvalid: true,
    pages: pages,
    theme: 'ios',
    themeVariant: 'light',
    touchUi: true, // renders gray background behind popup
    onChange: (event, inst) => {
      this.changeInputValue(this.selectedDate[0], this.selectedDate[1]);
    },
    buttons: [
      {
        text: 'Abbrechen',
        cssClass: 'cancel button',
        handler: 'cancel'
      },
      {
        text: 'Übernehmen',
        cssClass: 'confirm button',
        handler: 'set'
      },
    ]
  };

  /* LOCATION */
  location = this.env.defaultLocation;
  locations: any;

  /* SEARCH BUTTON */
  start = '';
  end = '';
  disabledSearchButton = false;

  /* DATEPICKER FUNCTIONS */
  changeInputValue(start: string, end: string): void {
    if (start === "" || end === "") return;
    this.dateInput = formatDate(dateFormat, new Date(start)) + ' - ' + formatDate(dateFormat, new Date(end));
    this.bookingService.addToSessionData("start", formatDate(isoDateFormat, new Date(start)));
    this.bookingService.addToSessionData("end", formatDate(isoDateFormat, new Date(end)));
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  setCalenderSettings(settings: any): void {
    this.firstAvailableDate = this.addDays(new Date(), parseInt(settings.first_available_date));
    this.lastAvailableDate = this.addDays(new Date(), parseInt(settings.last_available_date));
  }

  setInvalidDates(disabledDates: Array<any>): void {
    let invalid: Array<any> = [];
    disabledDates.forEach((item: any) => {
      let entry;
      switch (item.type) {
        case 'date':
          if (item.repeat === 'off') {
            entry = {
              date: new Date(item.disabled)
            }
          }
          else {
            entry = {
              date: new Date(item.disabled),
              recurring: {
                repeat: 'yearly'
              }
            }
          }
          invalid.push(entry);
          break;
        case 'day':
          entry = {
            recurring: {
              repeat: 'weekly',
              weekDays: this.mappingDayToWeekDay(item.disabled),
            }
          }
          invalid.push(entry);
          break;
        case 'daterange' :
          if (item.repeat === 'off') {
            entry = {
              // Need to add/subtract a day because due to inRangeInvalid set to true the first and last date of ranges is valid as checkin/checkout
              start: this.bookingService.subtractDays(new Date(item.disabled[0]), 1),
              end: this.bookingService.addDays(new Date(item.disabled[1]), 1),
            }
          }
          else {
            entry = {
              // Need to add/subtract a day because due to inRangeInvalid set to true the first and last date of ranges is valid as checkin/checkout
              start: this.bookingService.subtractDays(new Date(item.disabled[0]), 1),
              end: this.bookingService.addDays(new Date(item.disabled[1]), 1),
              recurring: {
                repeat: 'yearly'
              }
            }
          }
          invalid.push(entry);
          break;
      }
      this.invalid = invalid;
    });
  }

  mappingDayToWeekDay(weekDay: number): string {
    let mapping = [];
    mapping[1] = 'MO';
    mapping[2] = 'TU';
    mapping[3] = 'WE';
    mapping[4] = 'TH';
    mapping[5] = 'FR';
    mapping[6] = 'SA';
    mapping[7] = 'SU';
    return mapping[weekDay];
  }

  /* LOCATION FUNCTIONS */
  onSelected(location: any): void {
    // add to session
    this.bookingService.addToSessionData("location", location);
  }


  ngOnInit(): void {
    // First get promise from service, then get value out of it
    this.bookingService.getCalendarSettings().then((result) => {
      this.setCalenderSettings(result.settings);
      this.setInvalidDates(result.disabled_dates);
    });
    this.bookingService.getLocations().then((result) => {
      this.bookingService.addToSessionData('locations', result);
      this.locations = this.bookingService.getSessionData('locations');
    });

  }

  constructor(
    private bookingService: BookingService,
  ) {

  }
}
