import { MbscModule } from '@mobiscroll/angular';
import { FormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { AppComponent } from './app.component';
import { BookingService } from './booking.service';
import { BookingSearchFormComponent } from './booking-search-form/booking-search-form.component';


@NgModule({
  declarations: [
    AppComponent,
    BookingSearchFormComponent,
  ],
  imports: [ 
    MbscModule, 
    FormsModule,
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxWebstorageModule.forRoot({ prefix: 'vby', separator: '.', caseSensitive:true }),

  ],
  providers: [
    BookingService,

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
