<h2 class="{{this.env.shortcode}}">{{this.env.headline}}</h2>
<mbsc-input class="mb-3 datepicker" [(ngModel)]="dateInput" label="Reisezeitraum" inputStyle="outline" placeholder="Datum wählen"
            labelStyle="stacked" (click)="datePicker.open()" ></mbsc-input>
<mbsc-datepicker #datePicker [(ngModel)]="selectedDate"
                 [min]="firstAvailableDate"
                 [max]="lastAvailableDate"
                 [options]="calendarOptions"
                 [invalid]="invalid"
                 hidden>

</mbsc-datepicker>

<mbsc-dropdown #loc [(ngModel)]="this.location" label="Standort" inputStyle="outline" labelStyle="stacked"
               (onChange)="onSelected(loc.value)">
  <option *ngFor="let loc of this.locations" value="{{loc.id}}">{{loc.title}}</option>
</mbsc-dropdown>

<form method="get" enctype="multipart/form-data" action="{{ this.landingPage }}" target="_blank" ngNoForm>
    <input type="text" name="start" value="{{ this.selectedDate[0] }}" hidden />
    <input type="text" name="end" value="{{ this.selectedDate[1] }}" hidden />
    <input type="text" name="loc" value="{{ location }}" hidden />
  <button type="submit" class="button-primary {{this.env.shortcode}}" [disabled]="this.disabledSearchButton">{{this.env.buttonText}}</button>
  <button type="submit" class="button-skip {{this.env.shortcode}}" style="margin-top:1em" > {{this.env.buttonText2}} <svg xmlns="http://www.w3.org/2000/svg" height="1em" style="vertical-align:text-top" fill="currentColor" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></button>
</form>
